import { useState } from "react";

function MouseIndicator({ selectedIndex, onClick }) {
  //const [selectedIndex, setSelectedIndex] = useState(select);
  selectedIndex = selectedIndex + 1 + "";

  function IndicatorSelected(index) {
    onClick(index);
    //setSelectedIndex(index);
  }
  return (
    <div
      className="indicatorcontainer"
      style={{
        position: "relative",
        width: "42%",
        height: "auto",
        margin: "0 auto",
      }}
    >
      <img
        src="/mouse1.png"
        alt="Mouse"
        style={{
          height: "100%",
          height: "auto",
          display: "block",
          width: "100%",
          maxWidth: "400px",
          overflow: "hidden",
          margin: "auto auto",
        }}
        className="mouse-image"
      />

      <Indicator
        select={selectedIndex}
        text="点击左键"
        isLeft={true}
        startX="70%"
        startY="12%"
        index="1"
        indicatorClicked={IndicatorSelected}
      />

      <Indicator
        select={selectedIndex}
        text="点击右键"
        isLeft={false}
        startX="70%"
        startY="12%"
        index="2"
        indicatorClicked={IndicatorSelected}
      />

      <Indicator
        select={selectedIndex}
        text="增加DPI"
        isLeft={true}
        startX="96%"
        startY="39%"
        index="3"
        indicatorClicked={IndicatorSelected}
      />

      <Indicator
        select={selectedIndex}
        text="中键"
        isLeft={false}
        startX="52%"
        startY="25%"
        index="4"
        indicatorClicked={IndicatorSelected}
      />

      <Indicator
        select={selectedIndex}
        text="减少DPI"
        isLeft={true}
        startX="93%"
        startY="52%"
        index="5"
        indicatorClicked={IndicatorSelected}
      />

      <Indicator
        select={selectedIndex}
        text="DPI按键"
        isLeft={false}
        startX="97%"
        startY="38%"
        index="6"
        indicatorClicked={IndicatorSelected}
      />
    </div>
  );
}

function Indicator({
  select,
  text,
  isLeft,
  startX,
  startY,
  index,
  indicatorClicked,
}) {
  if (isLeft) {
    return (
      <>
        <div
          style={{
            position: "absolute",
            right: "130%",
            top: startY,
            transform: "translateY(-50%)",
            background: "transparent",
            padding: "2px 5px",
            fontSize: "1.2vh",
            color: "black",
            display: "flex",
            cursor: "pointer",
            borderRadius: "3px",
          }}
          onClick={() => {
            indicatorClicked(index);
          }}
        >
          <span
            style={{
              textAlign: "center",
              lineHeight: "1vw",
              marginRight: "5px",
              display: "inline-block",
              textAlign: "center",
              textDecoration: select === index ? "underline" : "none",
              color: select === index ? "orange" : "black",
            }}
          >
            {text}
          </span>

          <span
            style={{
              width: "1vw",
              height: "1vw",
              backgroundColor: select === index ? "orange" : "black",
              color: "white",
              borderRadius: "50%",
              display: "inline-block",
              textAlign: "center",
              lineHeight: "1vw",
            }}
          >
            {index}
          </span>
        </div>

        <div
          className="indicator-line"
          style={{
            position: "absolute",
            top: startY,
            height: "1px",
            width: `calc(130% - ${startX})`,
            backgroundColor: "#bcbcbc",
            right: startX,
          }}
        ></div>
      </>
    );
  } else {
    return (
      <>
        <div
          className="indicator-line"
          style={{
            position: "absolute",
            top: startY,
            height: "1px",
            width: `calc(130% - ${startX})`,
            backgroundColor: "#bcbcbc",
            left: startX,
          }}
        ></div>

        <div
          style={{
            position: "absolute",
            left: "130%",
            top: startY,
            transform: "translateY(-50%)",
            padding: "2px 5px",
            fontSize: "1.2vh",
            color: "black",
            display: "flex",
            cursor: "pointer",
            borderRadius: "3px",
            background: "transparent",
          }}
          onClick={() => {
            indicatorClicked(index);
          }}
        >
          <span
            style={{
              width: "1vw",
              height: "1vw",
              marginRight: "5px",
              backgroundColor: select === index ? "orange" : "black",
              color: "white",
              borderRadius: "50%",
              display: "inline-block",
              textAlign: "center",
              lineHeight: "1vw",
            }}
          >
            {index}
          </span>
          <span
            style={{
              height: "1vw",
              textAlign: "center",
              lineHeight: "1vw",
              textDecoration: select === index ? "underline" : "none",
              color: select === index ? "orange" : "black",
            }}
          >
            {text}
          </span>
        </div>
      </>
    );
  }
}

export default MouseIndicator;
