import { useState } from "react";
import SquareButton from "./SquareButton";
import UnderlineValue from "./UnderlineValue";

function CheckItem({
  names,
  text,
  color,
  checked,
  size,
  length,
  buttonClick,

  selectColorClick,
  colorChecked,
}) {
  const [isChecked, setChecked] = useState(checked);

  function onItemSelected(item, checked) {
    buttonClick(text, checked, color);

    setChecked(checked);
  }
  return (
    <div
      style={{
        display: "flex",
        width: "50%",
        marginLeft: "0",
        paddingBottom: "2px",
        whiteSpace: "nowrap",
        alignItems: "flex-end",
        justifyContent: "center",
      }}
    >
      <SquareButton
        names={names}
        color={color}
        size={size}
        checked={isChecked}
        onClickFunc={onItemSelected}
      />

      <UnderlineValue
        names={text}
        length={length}
        colorChecked={colorChecked}
        selectColorClick={selectColorClick}
      />
    </div>
  );
}
export default CheckItem;
