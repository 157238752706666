import { useState } from "react";

function UnderlineValue({ names, length, colorChecked, selectColorClick }) {
  //console.log("UnderlineValue is " + checked);
  return (
    <div
      style={{
        width: length,
        padding: "0px",
        height: "40px",
        marginLeft: "1vh",
        display: "flex",
        flexDirection: "column",
        borderBottom: "2px solid #eee",
        justifyContent: "flex-end",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => selectColorClick()}
    >
      <p
        style={{
          margin: 0,
          marginBottom: "-2px",
          paddingBottom: "0px",
          alignSelf: "center",
          width: "40%",
          height: "100%",
          lineHeight: "50px",
          borderBottom: colorChecked
            ? "black solid 2px"
            : "transparent solid 2px",
        }}
      >
        {names}
      </p>
    </div>
  );
}

export default UnderlineValue;
