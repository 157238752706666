import "../i18n";
import React, { useState, useEffect } from "react";
import "../App.css";
import Sidebar from "../Sidebar";
import SettingComp from "../SettingComp";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function SettingsPage() {
  const common = useSelector((state) => state.common);
  const isConnected = common.isConnected;
  const navigate = useNavigate();

  if (!isConnected) {
    navigate("/");
  }

  return (
    <div className="app">
      <Sidebar />
      <SettingComp />
    </div>
  );
}

export default SettingsPage;
