import "../i18n";
import React, { useState, useEffect } from "react";
import "../App.css";
import Sidebar from "../Sidebar";
import ConnectComp from "../ConnectComp";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userClickMenuAction } from "../webhid/action";

function ConnectPage() {
  const common = useSelector((state) => state.common);
  const isConnected = common.isConnected;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (isConnected) {
    dispatch(userClickMenuAction("buttons"));
    navigate("/buttons");
  }

  return (
    <div className="app">
      <Sidebar />
      <ConnectComp />
    </div>
  );
}

export default ConnectPage;
