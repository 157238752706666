// src/store.js
import { configureStore } from "@reduxjs/toolkit"; // Updated import
import rootReducer from "./rootReducer"; // Updated import

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // 默认使用 localStorage
import { thunk } from "redux-thunk"; // Change to named import

// 配置持久化
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const StateStore = configureStore({
  // Updated store creation
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable the serializable check if necessary
    }).concat(thunk), // Add thunk middleware
});

const persistor = persistStore(StateStore);

export { StateStore, persistor };
