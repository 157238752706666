import { useDispatch } from "react-redux";
import { connectedDeviceAction } from "./action";

import {
  CONNECTED_DEVICE,
  DISCONNECT_DEVICE,
  SEND_DATA,
  RECEIVE_DATA,
  RESTORE_ALL_CONF,
  SET_FATAL_LOG,
  USER_CLICK_MENU,
  CHANGE_APP_LANG,
} from "./action";

const initialState = {
  isConnected: false,
  fatalLog: "", // for debug.

  productName: "",
  menu: "",
  appConfig: { lang: null },
  sendData: [],

  // {category:0-3, data: 0, extra:1000 },
  buttonMap: [
    [{}, {}, {}, {}, {}, {}],
    [{}, {}, {}, {}, {}, {}],
    [{}, {}, {}, {}, {}, {}],
  ],

  dpiSelectArray: [
    { value: 400, color: "#68b932", enabled: true },
    { value: 600, color: "#3299AB", enabled: true },
    { value: 800, color: "#AB3364", enabled: true },
    { value: 1200, color: "#9A3B6C", enabled: true },
    { value: 1600, color: "#8B9A15", enabled: true },
    { value: 3200, color: "#CCCCCC", enabled: true },
  ],

  dpiOtherOptions: [false, false],
  reportRate: 0,
  reportLod: 0,
  ppTime: 0,
};

function commonReducer(state = initialState, action) {
  console.log(
    "We reducer get action " + action.type,
    "payload is " + action.payload,
    "our old state is",
    state
  );

  switch (action.type) {
    case CONNECTED_DEVICE:
      console.log("we have set isConnected", action.payload);
      return {
        ...state,
        isConnected: action.payload,
        productName: action.productName,
      };

    case DISCONNECT_DEVICE:
      return { ...state, isConnected: false };
    case SEND_DATA:
      return { ...state, sendData: [...state.sendData, action.payload] };
    case RECEIVE_DATA:
      return { ...state, sendData: [...state.sendData, action.payload] };
    case RESTORE_ALL_CONF:
      return { ...state, ...initialState.appConfig };

    case SET_FATAL_LOG:
      return { ...state, fatalLog: action.payload };

    case USER_CLICK_MENU:
      return { ...state, menu: action.payload };

    case CHANGE_APP_LANG:
      let currentconfig = { ...state.appConfig };
      currentconfig.lang = action.payload;

      let result = { ...state, appConfig: currentconfig };
      console.log("We get app config", currentconfig, result);
      return result;

    case "DPI_VALUE_CLICKED":
      let currentselect = JSON.parse(JSON.stringify(state.dpiSelectArray));

      let replace = false;
      currentselect.forEach((item, index) => {
        if (item.value === action.payload.value) {
          currentselect[index] = action.payload;
          replace = true;
        }
      });

      if (!replace) {
        currentselect.push(action.payload);
      }

      return { ...state, dpiSelectArray: currentselect };

    case "DPI_OTHER_OPTION":
      let others = [...state.dpiOtherOptions];
      others[action.index] = action.checked;
      return { ...state, dpiOtherOptions: others };

    case "DPI_REPORT_RATE":
      return { ...state, reportRate: action.payload };

    case "DPI_LOD_DISTANCE":
      return { ...state, reportLod: action.payload };

    case "DPI_POSTPONE_TIME":
      return { ...state, ppTime: action.payload };

    case "BUTTON_MAP_UPDATE_CATEGORY":
      console.log(
        "BUTTON config ++++++++ ",
        action.payload,
        action.tab,
        action.button
      );
      let newObj = JSON.parse(JSON.stringify(state.buttonMap));
      newObj[action.tab][action.button] = action.payload;
      return { ...state, buttonMap: newObj };
    default:
      return state;
  }
}

export default commonReducer;
