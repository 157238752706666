function NavigationBack({ color, size, text, buttonClick }) {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        whiteSpace: "nowrap",
        justifyContent: "left",
        alignItems: "center",
        cursor: buttonClick ? "pointer" : "auto",
      }}
      onClick={buttonClick}
    >
      {buttonClick && (
        <img src="/arrow-left.png" style={{ width: "24px", height: "24px" }} />
      )}

      <p style={{ fontSize: "0.8rem", fontWeight: "bold" }}>{text}</p>
    </div>
  );
}
export default NavigationBack;
