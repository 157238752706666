import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import "./MainSubBlock.css";
import { restoreAllConfigures, sendDataAction } from "./webhid/action";
import DPISelector from "./DPISelector";
import CenteredButton from "./components/CenteredButton";
import { useSelector, useDispatch } from "react-redux";
import NavigationBack from "./components/NavigationBack";
import Wheel from "@uiw/react-color-wheel";
import { hsvaToHex } from "@uiw/color-convert";
import { updateDpiValueAction } from "./webhid/action";

function DpiComp() {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const [isCustomColor, setCustomColor] = useState(true);

  const [red, setRed] = useState(0);
  const [green, setGreen] = useState(0);
  const [blue, setBlue] = useState(0);
  const [dpiColor, setDpiColor] = useState("black");
  const [changeColorForDpi, setChangeColorForDpi] = useState(-1);

  const colors = ["#81b5e6", "#abe647", "#b665e6", "#4369e6", "#335f9b"]; // Example array

  function onColorBtnClicked() {}

  const [hsva, setHsva] = useState({ h: 214, s: 43, v: 90, a: 1 });

  return (
    <div className="main-content" style={{ whiteSpace: "nowrap" }}>
      {isCustomColor ? (
        <div className="main-panel" style={{ marginLeft: "25vh", flex: 4 }}>
          <div
            style={{
              width: "35%",
              margin: "0 auto",
              marginBottom: "25px",
            }}
          >
            <img
              src="/mouse1.png"
              alt="Mouse"
              style={{
                height: "100%",
                display: "block",
                width: "100%",
                maxWidth: "400px",
                overflow: "hidden",
                margin: "auto",
                cursor: "pointer",
              }}
              className="mouse-image"
              onClick={() => {}}
            />
          </div>

          <div style={{ margin: "0 auto" }}>
            <CenteredButton
              buttonText={"自定义灯效"}
              onClick={() => {
                setCustomColor((prevState) => {
                  return !prevState;
                });
              }}
            />
          </div>
        </div>
      ) : (
        <div
          className="main-panel"
          style={{
            marginLeft: "25vh",
            flex: 4,
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <div style={{ margin: "30px 0 0 30px" }}>
            <NavigationBack
              text="自定义灯效"
              buttonClick={() => {
                console.log("Set custom color false");
                setCustomColor(true);
              }}
            />
          </div>

          <div style={{ margin: "1.5vh auto" }}>
            <Wheel
              color={hsva}
              onChange={(color) => {
                setHsva({ ...hsva, ...color.hsva });
                let hexColor = hsvaToHex({ ...hsva, ...color.hsva });

                console.log("We set dpi color in step one is " + hexColor);
                setDpiColor(hexColor);

                const red = parseInt(hexColor.substring(1, 3), 16); // "ee" -> 238
                setRed(red);
                const green = parseInt(hexColor.substring(3, 5), 16); // "bb" -> 187
                setGreen(green);
                const blue = parseInt(hexColor.substring(5, 7), 16); // "cc" -> 204
                setBlue(blue);

                if (changeColorForDpi != -1) {
                  let dpiObj = {
                    value: changeColorForDpi,
                    color: hexColor,
                    enabled: true,
                  };

                  dispatch(updateDpiValueAction(dpiObj));
                }
              }}
            />
          </div>

          <div
            className="buttonGroup"
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              // border: "1px, solid purple",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "4vh",
                height: "4vh",
                margin: "2vh auto",
                borderRadius: "50%",
                margin: "1.1vh 0px 0px 0px",
                alignSelf: "flex-end",
                background: hsvaToHex(hsva),
                border: "1px solid black",
              }}
            />
            <div style={{ width: "10px" }}></div>
            <CenteredButton
              buttonText={"R " + red}
              onClick={onColorBtnClicked}
            />

            <div style={{ width: "10px" }}></div>
            <CenteredButton
              buttonText={"G " + green}
              onClick={onColorBtnClicked}
            />
            <div style={{ width: "10px" }}></div>
            <CenteredButton
              buttonText={"B " + blue}
              onClick={onColorBtnClicked}
            />
          </div>

          <div>
            <p
              style={{
                fontSize: "0.7rem",
                marginLeft: "5vh",
                marginBottom: "0",
                marginTop: "35px",
                fontWeight: "bold",
              }}
            >
              历史颜色
            </p>
          </div>
          <div
            style={{
              marginLeft: "6vh",
              marginTop: "1.4vh",
              display: "flex",
            }}
          >
            {colors.map((color, index) => (
              <div
                key={index}
                style={{
                  width: "2vh",
                  height: "2vh",
                  borderRadius: "50%",
                  margin: "0.2vh 15px 0px 0px",
                  alignSelf: "flex-end",
                  background: color,
                }}
              />
            ))}
          </div>
        </div>
      )}

      <div className="sub-panel" style={{ marginRight: "25vh", flex: 4 }}>
        <DPISelector
          dpiColor={dpiColor}
          uploadChangeColorOfDpiValue={(dpiValue) => {
            setDpiColor("black");
            setChangeColorForDpi(dpiValue);
          }}
        />
      </div>
    </div>
  );
}

export default DpiComp;
