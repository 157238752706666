import React, { useState } from "react";
import "./DPISelector.css";
import TabNavigation from "./components/TabNavigation";
import Subtile from "./components/Subtitle";
import CheckItem from "./components/CheckItem";
import SquareButton from "./components/SquareButton";
import CheckboxText from "./components/CheckboxText";
import { useDispatch, useSelector } from "react-redux";
import {
  sendReportRateAction,
  sendReportLodAction,
  sendDPIOtherOptAction,
  updateDpiValueAction,
  sendPostPoneTimeAction,
} from "./webhid/action";
import SendDataToHID from "./webhid/sendData";
import { LodMap, ReportRateMap, PostPoneMap } from "./keymap/ReportRate";

function DPISelector({ dpiColor, uploadChangeColorOfDpiValue }) {
  const [dpi, setDpi] = useState(1200);
  const [progress, setProgress] = useState(50);
  const [selectedPreset, setSelectedPreset] = useState(1200);

  console.log("We select corlor is " + dpiColor);

  const [colorDpiValue, setColorOfDpiValue] = useState(0);

  const common = useSelector((state) => state.common);

  const dpiPredefinedArray = common.dpiSelectArray;

  const reportRate = common.reportRate;
  const otherOptions = common.dpiOtherOptions;
  //console.log("We get array Select", arraySlect);
  const dispatch = useDispatch();

  const sliderStyle = {
    background: `linear-gradient(90deg, #000000 2%, transparent 0) 50% 50% / 15%
      40% repeat-x,
    linear-gradient(90deg, orange 2%, orange 100%) 1% 54% / ${
      progress - 1
    }% 40% no-repeat,
    linear-gradient(90deg, #000000 75%, #000000 100%) 76% 48% / 1px 30%
      no-repeat,
    linear-gradient(90deg, #000000 75%, #000000 100%) 79% 48% / 1px 30%
      no-repeat,
    linear-gradient(90deg, #000000 75%, #000000 100%) 82% 48% / 1px 30%
      no-repeat,
    linear-gradient(90deg, #000000 75%, #000000 100%) 85% 48% / 1px 30%
      no-repeat,
    linear-gradient(90deg, #000000 75%, #000000 100%) 91% 48% / 1px 30%
      no-repeat,
    linear-gradient(90deg, #000000 75%, #000000 100%) 94% 48% / 1px 30%
      no-repeat,
    linear-gradient(90deg, #000000 75%, #000000 100%) 97% 48% / 1px 30%
      no-repeat`,
  };

  //const reportRates = [125, 500, 1000, 2000, 4000, 8000];
  const reportRates = Object.keys(ReportRateMap);
  const lods = Object.keys(LodMap);
  const postponeTime = [2, 4, 8];

  const reportLod = common.reportLod;

  const ppTime = common.ppTime;

  // 假设 sliderValue 在 0 到 1 之间
  function mapSliderToRange(sliderValue) {
    const min = 50;
    const max = 26000;
    let mappedValue = min * Math.pow(max / min, sliderValue / 100.0);

    if (mappedValue < 100) {
      mappedValue = Math.round(mappedValue / 5) * 5;
    } else if (mappedValue >= 100 && mappedValue < 200) {
      mappedValue = Math.round(mappedValue / 10) * 10;
    } else if (mappedValue >= 200 && mappedValue < 1000) {
      mappedValue = Math.round(mappedValue / 50) * 50;
    } else if (mappedValue >= 1000) {
      mappedValue = Math.round(mappedValue / 200) * 200;
    }
    return Math.round(mappedValue);
  }

  const handleDpiChange = (e) => {
    console.log(
      "we get dpi set from slide " + mapSliderToRange(e.target.value)
    );
    setDpi(mapSliderToRange(e.target.value));
    setProgress(e.target.value);
    setSelectedPreset(null); // 当手动调整 DPI 时取消预设选择
  };

  const handleReportRateClick = (rate) => {
    dispatch(sendReportRateAction(rate));
    let command = ReportRateMap[rate];
    console.log("Report rate " + rate + " is selected.", command);
    SendDataToHID("40 " + command);
  };

  const handleReportLodClick = (lod) => {
    dispatch(sendReportLodAction(lod));
    let command = LodMap[lod];
    console.log("Report lod " + lod + " is selected.", command);
    SendDataToHID("4C " + command);
  };

  const handlePostponeTimeClick = (pt) => {
    dispatch(sendPostPoneTimeAction(pt));
    let command = PostPoneMap[pt];
    console.error("Report postpone time " + pt + " ms is selected.", command);
    //SendDataToHID("XX " + command);
  };

  const handleDPIValueClicked = (names, checked, color) => {
    console.log("We click button " + names + " checked " + checked);

    let dpiObj = {
      value: names,
      color: color,
      enabled: checked,
    };
    dispatch(updateDpiValueAction(dpiObj));
  };

  const [activeTab, setActiveTab] = useState(1);
  const tabNavData = ["回报率", "LOD静默高度", "按键防抖延迟", "其他"];

  return (
    <div
      className="dpi-selector"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        margin: 0,
        padding: 0,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      <Subtile text="灵敏度（DPI）" />
      <div
        className="slider-container"
        style={{
          width: "100%",
          //border: "1px solid red",
          margin: "1vh 0vh",
          flex: 3,
        }}
      >
        <DisableText
          text="恢复默认设置"
          onClick={() => {
            console.log("We have run");
          }}
        />

        <div
          className="dpi-value"
          style={{
            width: "100%",
            marginTop: "2vh",
            position: "relative",
            textAlign: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: "100%",
              left: progress + "%",
              transform: "translateX(-50%)",
              fontSize: "0.8rem",
            }}
          >
            {dpi}
          </div>
        </div>

        <input
          className="dpi-slider"
          type="range"
          // style={{ width: "100%" }}
          style={sliderStyle}
          min="0"
          max="100"
          value={progress}
          onChange={handleDpiChange}
        />
        <div className="slider-label">
          <span>50</span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span style={{ marginLeft: "2vh" }}>3200</span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span>26000</span>
        </div>

        {/* dpiPredefinedArray */}

        <div
          style={{
            marginBottom: "1vh",
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
          }}
        >
          {dpiPredefinedArray.map((item, index) => (
            <CheckItem
              names={index}
              text={item.value}
              color={
                item.value === colorDpiValue && dpiColor !== "black"
                  ? dpiColor
                  : item.color
              }
              size="2vh"
              checked={item.enabled}
              length="18vh"
              buttonClick={handleDPIValueClicked}
              selectColorClick={() => {
                setColorOfDpiValue(item.value);

                uploadChangeColorOfDpiValue(item.value);
              }}
              colorChecked={item.value === colorDpiValue}
            />
          ))}
        </div>

        <DisableText text="单击方块以启动/禁用DPI档位" />
      </div>

      <div style={{ width: "100%", margin: 0, padding: 0, flex: 2 }}>
        <TabNavigation
          data={tabNavData}
          onClickFunc={setActiveTab}
          activeTab={activeTab}
        />

        <div
          className="report-rates"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          {activeTab === 1 &&
            reportRates.map((rate) => (
              <div
                key={rate}
                style={{ padding: "6px", margin: "auto 1px" }}
                className={`rate ${rate === reportRate ? "active" : ""}`}
                onClick={() => handleReportRateClick(rate)}
              >
                {rate} Hz
              </div>
            ))}
          {activeTab === 2 &&
            lods.map((lod) => (
              <div
                key={lod}
                style={{ padding: "6px", margin: "auto 1px" }}
                className={`rate ${lod === reportLod ? "active" : ""}`}
                onClick={() => handleReportLodClick(lod)}
              >
                {lod} mm
              </div>
            ))}
          {activeTab === 3 &&
            postponeTime.map((pt, index) => (
              <div
                key={index}
                style={{ padding: "6px", margin: "auto 1px" }}
                className={`rate ${pt === ppTime ? "active" : ""}`}
                onClick={() => handlePostponeTimeClick(pt)}
              >
                {pt} ms
              </div>
            ))}

          {activeTab === 4 && (
            <div
              className="otheroptions"
              style={{ display: "flex", width: "100%" }}
            >
              <CheckboxText
                text="移动时关闭电量指示灯"
                index="0"
                checked={otherOptions[0]}
                buttonClick={(index, checked) => {
                  console.log(
                    "we active here " + index + " checked " + checked
                  );
                  dispatch(sendDPIOtherOptAction(0, checked));
                  SendDataToHID("46 00 03");
                }}
              />
              <CheckboxText
                text="鼠标移动唤醒移动"
                index="1"
                checked={otherOptions[1]}
                buttonClick={(index, checked) => {
                  console.log(
                    "we are ative here name " + index + " checked " + checked
                  );
                  dispatch(sendDPIOtherOptAction(1, checked));
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const DisableText = ({ text, onClick }) => {
  return (
    <div style={{ margin: "0 0 0 auto" }}>
      <button
        style={{
          border: "none",
          color: "#ccc",
          margin: "3px 0 6px 0",
          cursor: onClick ? "pointer" : "auto",
          fontSize: "0.6rem",
          backgroundColor: "transparent",
        }}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        {text}
      </button>
    </div>
  );
};

const OtherOptions = ({ onItemSelected }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <SquareButton
        names="移动时关闭电量指示灯"
        color="orange"
        size="1.4vh"
        onClickFunc={onItemSelected}
      />

      {/* <SquareButton
        names="鼠标移动唤醒"
        color="orange"
        size="1.4vh"
        onClickFunc={onItemSelected}
      /> */}
    </div>
  );
};

export default DPISelector;
