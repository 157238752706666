export const CONNECTED_DEVICE = "CONNECTED_DEVICE";
export const DISCONNECT_DEVICE = "DISCONNECT_DEVICE";
export const SEND_DATA = "SEND_DATA";
export const RECEIVE_DATA = "RECEIVE_DATA";
export const RESTORE_ALL_CONF = "RESTORE_ALL_CONF";
export const USER_CLICK_MENU = "USER_CLICK_MENU";
export const SET_FATAL_LOG = "SET_FATAL_LOG";
export const CHANGE_APP_LANG = "CHANGE_APP_LANG";

// DEBUG
export const sendFatalLogAction = (logInfo) => ({
  type: SET_FATAL_LOG,
  payload: logInfo,
});

// SYSTEM
export const changeAppLanguage = (lang) => ({
  type: CHANGE_APP_LANG,
  payload: lang,
});

// CONNECTION
/* Connect Async action */
export const connectDeviceAction = () => {
  return async (dispatch) => {
    console.log("We begin to connect to devices");

    if (!navigator.hid) {
      console.error(
        "Your browser does not support HID, please use chrome based browser."
      );
      dispatch(sendFatalLogAction("Your browser is not supported"));
      dispatch(connectedDeviceAction(false, null));

      return;
    }

    const devicesConncted = await navigator.hid.getDevices();
    for (const device of devicesConncted) {
      if (device.opened) {
        console.warn("You already connected to a devices");
        dispatch(
          connectedDeviceAction(
            true,
            getShortProductName(await device.productName)
          )
        );
        dispatch(sendFatalLogAction("Connect success."));
        return;
      }
    }

    const devices = await navigator.hid.requestDevice({
      // filters: [{ usagePage: 0x01, usage: 0x02 }],
      filters: [
        {
          vendorId: 0x369b, // Replace with your device's vendor ID
          productId: 0x0163,
        },
      ],
    });

    if (devices.length === 0) {
      console.log("No device selected.");
      dispatch(connectedDeviceAction(false, null));
      return;
    }

    let device = devices[0];

    try {
      await device.open();
      dispatch(
        connectedDeviceAction(true, getShortProductName(device.productName))
      );
      dispatch(sendFatalLogAction("Connect success."));
    } catch (e) {
      console.log("Open hid devices failed with error", e);
      dispatch(connectedDeviceAction(false, null));
      dispatch(sendFatalLogAction("Open hid devices failed with error" + e));
    }

    console.log("We add event handler here");
    device.addEventListener("inputreport", handleInputReport);
  };
};

export const connectedDeviceAction = (success, productName) => ({
  type: CONNECTED_DEVICE,
  payload: success,
  productName: productName,
});

export const disconnectDeviceAction = () => ({ type: DISCONNECT_DEVICE });

export const sendDataAction = (data) => ({ type: SEND_DATA, payload: data });
export const receiveDataAction = (data) => ({
  type: RECEIVE_DATA,
  payload: data,
});

// UI -> Sidebar.
export const userClickMenuAction = (menuName) => ({
  type: USER_CLICK_MENU,
  payload: menuName,
});

// UI-> Buttons
export const updateCategorDataAction = (categoryObj, tab, button) => ({
  type: "BUTTON_MAP_UPDATE_CATEGORY",
  payload: categoryObj,
  tab: tab,
  button: button,
});

// UI -> DPI
export const updateDpiValueAction = (dpiObj) => ({
  type: "DPI_VALUE_CLICKED",
  payload: dpiObj,
});

export const sendReportRateAction = (rate) => ({
  type: "DPI_REPORT_RATE",
  payload: rate,
});

export const sendReportLodAction = (lod) => ({
  type: "DPI_LOD_DISTANCE",
  payload: lod,
});

export const sendPostPoneTimeAction = (pt) => ({
  type: "DPI_POSTPONE_TIME",
  payload: pt,
});

export const sendDPIOtherOptAction = (index, checked) => ({
  type: "DPI_OTHER_OPTION",
  index: index,
  checked: checked,
});

export const restoreAllConfigures = () => ({ type: RESTORE_ALL_CONF });

// UI -> Macro
export const sendMacroOptionSelectedAction = (index, checked) => ({
  type: "MACRO_OPTION_CLICKED",
  index: index,
  checked: checked,
});

export const sendMacroAddedAction = (action) => ({
  type: "MACRO_ADDED",
  payload: action,
});

export const sendMacroDeletedAction = (index) => ({
  type: "MACRO_DELETED",
  index: index,
});

export const sendMacroReplaceAction = (index, action) => ({
  type: "MACRO_REPLACED",
  index: index,
  payload: action,
});

////////////////////////////////////////////////////////////////////

function getShortProductName(longName) {
  let start = longName.indexOf("IQUNIX");
  start += "IQUNIX".length;
  return longName.substring(start).trim().split(/\s+/)[0];
}

function handleInputReport(event) {
  const { data, reportId } = event;
  let dataArray = Array.from(new Uint8Array(event.data.buffer));
  const hexArray = dataArray.map((num) => num.toString(16).padStart(2, "0")); // Convert to hex
  console.log("We received some event", hexArray, event);
}
