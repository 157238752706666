import "../App.css";
import Sidebar from "../Sidebar";
import ButtonComp from "../ButtonComp";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userClickMenuAction } from "../webhid/action";

function ButtonsPage() {
  const common = useSelector((state) => state.common);
  const isConnected = common.isConnected;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (!isConnected) {
    navigate("/");
  }
  return (
    <div className="app">
      <Sidebar />
      <ButtonComp />
    </div>
  );
}

export default ButtonsPage;
