import "../App.css";
import Sidebar from "../Sidebar";
import DpiComp from "../DpiComp";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function DpiPage() {
  const common = useSelector((state) => state.common);
  const isConnected = common.isConnected;
  const navigate = useNavigate();

  if (!isConnected) {
    navigate("/");
  }
  return (
    <div className="app">
      <Sidebar />
      <DpiComp />
    </div>
  );
}

export default DpiPage;
