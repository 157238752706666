const initialState = {
  macroIndex: 0,
  macroLists: [],
  macroSettings: [false, false, false, false],
};

function macroReducer(state = initialState, action) {
  console.log(
    "We macroReducer get action ",
    action,
    "our previous state is",
    state
  );

  switch (action.type) {
    case "MACRO_OPTION_CLICKED":
      let currentconfig = [...state.macroSettings];
      console.log("Before ", currentconfig);
      currentconfig.forEach((_, index) => {
        currentconfig[index] = false;
      });
      currentconfig[action.index] = action.checked;
      console.log("After ", currentconfig);
      let result = { ...state, macroSettings: currentconfig };
      console.log("We get app config", currentconfig, result);
      return result;

    case "MACRO_ADDED":
      return {
        ...state,
        macroLists: [...state.macroLists, action.payload],
        macroIndex: state.macroIndex + 1,
      };

    case "MACRO_DELETED":
      let exists = JSON.parse(JSON.stringify(state));
      exists.macroLists.splice(action.index, 1);
      return exists;

    case "MACRO_REPLACED":
      let exists1 = JSON.parse(JSON.stringify(state));
      exists1.macroLists[action.index] = action.payload;
      console.log("After replace, it's content is", exists1);
      return exists1;

    default:
      return state;
  }
}

export default macroReducer;
