/*
Value of USB Report rate :
125Hz 0x00
250Hz 0x01
500Hz 0x02
1000Hz 0x03
2000Hz 0x04
4000Hz 0x05
8000Hz 0x06
*/

export const ReportRateMap = {
  125: "00",
  250: "01",
  500: "02",
  1000: "03",
  2000: "04",
  4000: "05",
  8000: "06",
};

/*
Value of LOD :
1mm 0x0100
2mm 0x0200
*/
export const LodMap = {
  1: "01 00",
  2: "02 00",
};

/*
value of Postpone time
2 ms
4 ms
8 ms
*/
export const PostPoneMap = {
  2: "XX XX",
  4: "XX XX",
  8: "XX XX",
};
