function Subtile({ text }) {
  return (
    <div>
      <p
        style={{
          margin: "12px 0 2px 0",
          fontWeight: "bold",
          fontSize: "0.9rem",
        }}
      >
        {text}
      </p>
    </div>
  );
}

export default Subtile;
