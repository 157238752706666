import { useTranslation } from "react-i18next";
import { useState, useRef, useEffect } from "react";
import "./MainSubBlock.css";
import MouseIndicator from "./MouseIndicator";
import { restoreAllConfigures, sendDataAction } from "./webhid/action";

import { useSelector, useDispatch } from "react-redux";

import TabNavigation from "./components/TabNavigation";
import CenteredButton from "./components/CenteredButton";
import NavigationBack from "./components/NavigationBack";
import KeyPairsLine from "./components/KeyPairsLine";
import SeparatorLine from "./components/SeparatorLine";
import SendDataToHID from "./webhid/sendData";
import { mouseButtonMap, keyboardMap } from "./keymap/ConcreteKeymap";
import { systemKeyMap } from "./keymap/SystemKeyMap";
import CheckboxText from "./components/CheckboxText";
import {
  connectedDeviceAction,
  sendMacroOptionSelectedAction,
  updateCategorDataAction,
} from "./webhid/action";

import Subtile from "./components/Subtitle";
import ConfNavigation from "./components/ConfNavigation";

function ButtonComp() {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  // Save structure.
  const commonSlice = useSelector((state) => state.common);

  const buttonMap = commonSlice.buttonMap;
  const [activeTab, setActiveTab] = useState(0); // based 0, default is tab 0
  const [selectedMouseButton, setSelectedMouseButton] = useState(-1); //based 0, default is no selection.

  //
  const templateObj = {
    category: 0,
    dataIndex: 0,
    extraData: 0, // for categor == [0 key] / [1  count].
  };

  function currentCategory() {
    if (buttonMap[activeTab][selectedMouseButton].category === subPageIndex) {
      return buttonMap[activeTab][selectedMouseButton];
    } else {
      return null;
    }
  }

  function clearCategoryCacheData() {
    setSchema("");
    setInputKey("");
  }

  // for above category.
  const [subPageIndex, setSubPageIndex] = useState(-1);

  console.log(
    "We get current location :",
    activeTab,
    "==>",
    selectedMouseButton,
    "==>",
    subPageIndex
  );

  function mouseButtonClicked(index) {
    console.log("Button " + index + " was clicked.");
    setSelectedMouseButton(parseInt(index) - 1);
    setSubPageIndex(-1);
  }

  const macros = useSelector((state) => state.macro);

  const customSchemes = macros.macroLists;

  /* used for search box */
  const inputRef = useRef(null); // Create a ref for the input
  const [inputHeight, setInputHeight] = useState(0); // State to store input height

  useEffect(() => {
    if (inputRef.current) {
      setInputHeight(inputRef.current.clientHeight); // Set the height based on the input's clientHeight
    }
  }, [inputRef]);
  /* used for search box */

  const tabNavData = ["配置1", "配置2", "配置3"];

  const subButtons = ["特殊按键", "宏", "预设按键", "键盘按键"];

  function macroOptionClick(index, checked) {
    console.log("Option " + index + " is checked " + checked);

    let sendObj = { ...templateObj };
    sendObj.category = subPageIndex;
    sendObj.dataIndex = schema;
    sendObj.extraData = checked ? index : -1;

    //send Obj
    dispatch(updateCategorDataAction(sendObj, activeTab, selectedMouseButton));
  }

  function checkCategorValid(sendObj) {
    return sendObj.dataIndex >= 0 && sendObj.extraData.length > 0;
  }
  //"特殊按键"
  const [inputKey, setInputKey] = useState("");
  function specialButtonComposeClicked(index, checked) {
    console.log("Option " + index + " is checked " + checked);

    let sendObj = { ...currentCategory() };
    sendObj.category = subPageIndex;
    sendObj.dataIndex = index;
    sendObj.extraData = inputKey;

    if (checkCategorValid(sendObj)) {
      setSchema("");
    }

    //send Obj
    dispatch(updateCategorDataAction(sendObj, activeTab, selectedMouseButton));
  }
  // used for select control.
  const [schema, setSchema] = useState("");

  const checkBoxList = [
    "默认",
    "循环直到按键松开",
    "循环直到再次按下",
    "指定循环次数",
  ];

  const specialButtonCompistion = ["Ctrl", "Alt", "Shift", "Win"];
  function onButtonClicked(index) {
    setSubPageIndex(index);
  }
  return (
    <div className="main-content" style={{ whiteSpace: "nowrap" }}>
      <div className="main-panel" style={{ justifyContent: "center" }}>
        <ConfNavigation
          data={tabNavData}
          onClickFunc={(index) => {
            setActiveTab(index);
            setSelectedMouseButton(-1);
            setSubPageIndex(-1);
          }}
          activeTab={activeTab}
        />
        <MouseIndicator
          selectedIndex={selectedMouseButton}
          onClick={mouseButtonClicked}
        />

        <div style={{ margin: "1.5vh auto 0 auto" }}>
          <CenteredButton
            buttonText="恢复默认设置"
            onClick={() => {
              dispatch(restoreAllConfigures());
            }}
          />
        </div>
      </div>

      <div
        className="sub-panel"
        style={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          position: "relative",
          color: selectedMouseButton === -1 ? "#ddd" : "black",
        }}
      >
        {selectedMouseButton === -1 && (
          <div
            className="overlay"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              borderRadius: "8px",
              backgroundColor: "rgba(75, 75, 75, 0.09)",
              zIndex: "999",
            }}
          ></div>
        )}

        {subPageIndex === -1 && (
          <>
            <div style={{}}>
              <NavigationBack text="按键设置" />
            </div>
            <SeparatorLine />
            <div
              style={{
                margin: "2vh auto",
                display: "flex",
                alignContent: "flex-start",
                flexWrap: "wrap",
              }}
            >
              {subButtons.map((item, index) => (
                <CenteredButton
                  buttonText={item}
                  key={index}
                  onClick={() => {
                    onButtonClicked(index);
                  }}
                  fontColor={selectedMouseButton === -1 ? "#ddd" : "black"}
                />
              ))}
            </div>
          </>
        )}

        {subPageIndex === 0 && (
          <>
            <NavigationBack
              text={subButtons[subPageIndex]}
              buttonClick={() => {
                setSubPageIndex(-1);
              }}
            />

            <SeparatorLine />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "3vh",
                alignItems: "center",
              }}
            >
              <div
                className="buttonComposition"
                style={{
                  margin: "5px auto 5px auto",
                  display: "flex",
                  width: "40%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {specialButtonCompistion.map((item, index) => (
                  <CheckboxText
                    text={item}
                    key={index}
                    checked={
                      currentCategory()
                        ? currentCategory().dataIndex === index
                        : false
                    }
                    index={index}
                    buttonClick={(_, checked) => {
                      specialButtonComposeClicked(index, checked);
                    }}
                  />
                ))}
              </div>

              {/* Separator */}
              <div
                style={{
                  width: "4px",
                  height: "100%",
                  margin: "5px",
                  backgroundColor: "#eee",
                }}
              />

              <img
                src="/plus.png"
                style={{
                  maxWidth: "1.5vh",
                  width: "1.5vh",
                  maxHeigh: "1.5vh",
                  marginLeft: "1.5vh",
                  height: "1.5vh",
                }}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>请输入组合键:</div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "1vh auto",
                    justifyContent: "space-around",
                  }}
                >
                  <input
                    style={{ width: "40%" }}
                    type="text"
                    value={currentCategory() ? currentCategory().extraData : ""}
                    maxLength="1"
                    onChange={(e) => {
                      setInputKey(e.target.value);

                      let sendObj = { ...currentCategory() };
                      sendObj.extraData = e.target.value;

                      if (checkCategorValid(sendObj)) {
                        setSchema("");
                      }
                      //send Obj
                      dispatch(
                        updateCategorDataAction(
                          sendObj,
                          activeTab,
                          selectedMouseButton
                        )
                      );
                    }}
                  />
                </div>

                <div style={{ color: "white" }}>这不可见内容</div>
              </div>
            </div>

            {/* <div
              className="searchbox"
              style={{
                borderBottom: "1px solid #eee",
                margin: "2vh auto 5px auto",
                display: "flex",
                width: "100%",
              }}
            >
              <input
                type="text"
                ref={inputRef}
                style={{
                  width: "80%",
                  border: "none",
                  outline: "none",
                  fontSize: "0.6rem",
                  flex: 1,
                }}
                placeholder="搜索..."
              ></input>
              <img
                src="/search.png"
                style={{
                  height: `${inputHeight}px`,
                  width: `${inputHeight}px`,
                }}
              ></img>
            </div>
            <div
              className="scrollsection"
              style={{
                overflowY: "auto",
                width: "100%",
                flex: 1,
                //border: "1px red solid",
              }}
            >
              <Title text="Complile" />

              <KeyPairsLine
                encode="E0 19"
                text1="Ctrl + V"
                text2=" Paste"
                onClick={(encode) => {
                  let commandStr =
                    "48 " + mouseButtonMap[selectedMouseButton] + " " + encode;

                  console.log("We encode get " + commandStr);
                  SendDataToHID(commandStr);
                }}
              />
              <KeyPairsLine
                encode="E0 06"
                text1="Ctrl + C"
                text2=" Copy"
                onClick={(encode) => {
                  let commandStr =
                    "48 " + mouseButtonMap[selectedMouseButton] + " " + encode;

                  console.log("We encode get " + commandStr);
                  SendDataToHID(commandStr);
                }}
              />
              <KeyPairsLine text1="Ctrl + V" text2=" Paste" />

              <Title text="Windows" />
              <KeyPairsLine text1="Ctrl + V" text2=" Paste" />
              <KeyPairsLine text1="Ctrl + V" text2=" Paste" />
              <KeyPairsLine text1="Ctrl + V" text2=" Paste" />
              <KeyPairsLine text1="Ctrl + V" text2=" Paste" />
              <KeyPairsLine text1="Ctrl + V" text2=" Paste" />
              <KeyPairsLine text1="Ctrl + V" text2=" Paste" />
            </div>

            <div style={{ margin: "0 0 0 auto" }}>
              <button
                style={{
                  border: "none",
                  color: "#ccc",
                  marginTop: "1vh",
                  cursor: "pointer",
                  fontSize: "0.6rem",
                  backgroundColor: "transparent",
                }}
                onClick={() => {
                  dispatch(restoreAllConfigures());
                }}
              >
                禁用
              </button>
            </div> */}
          </>
        )}

        {subPageIndex === 1 && (
          <>
            <>
              <div>
                <NavigationBack
                  text={subButtons[subPageIndex]}
                  buttonClick={() => {
                    setSubPageIndex(-1);
                  }}
                />
              </div>
              <SeparatorLine />
              <select
                value={currentCategory() ? currentCategory().dataIndex : ""}
                style={{
                  margin: "15px 0px",
                  height: "3vh",
                  width: "100%",
                  fontSize: "0.72rem",
                }}
                onChange={(e) => {
                  setSchema(e.target.value);
                  // print the name of the option.
                  console.log("We click button", e.target.value);

                  let sendObj = { ...templateObj };

                  sendObj.category = subPageIndex;
                  sendObj.dataIndex = e.target.value;
                  sendObj.extraData = currentCategory()
                    ? currentCategory().extraData
                    : -1;

                  if (sendObj.dataIndex.length > 0 && sendObj.extraData >= 0) {
                    setInputKey("");
                  }
                  //send Obj
                  dispatch(
                    updateCategorDataAction(
                      sendObj,
                      activeTab,
                      selectedMouseButton
                    )
                  );
                }}
                onClick={() => {
                  const selectElement = document.querySelector("select"); // Get the select element
                  if (selectElement.options.length === 1) {
                    console.log("No options available");
                  } else {
                    console.log("Have available options.");
                  }
                }}
              >
                <option value="" disabled>
                  <p>请选择一个方案</p>
                </option>

                {customSchemes.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
              <Subtile text="播放选项" />

              {checkBoxList.map((item, index) => (
                <CheckboxText
                  text={item}
                  key={index}
                  // checked={optionSelect[index]}
                  checked={
                    currentCategory()
                      ? index === currentCategory().extraData
                      : false
                  }
                  index={index}
                  buttonClick={macroOptionClick}
                />
              ))}
            </>
          </>
        )}

        {subPageIndex === 2 && (
          <>
            <NavigationBack
              text={subButtons[subPageIndex]}
              buttonClick={() => {
                setSubPageIndex(-1);
              }}
            />

            <SeparatorLine />

            <div
              className="scrollsection"
              style={{ width: "100%", flex: 1, overflowY: "auto" }}
            >
              {systemKeyMap.map((item, index) => (
                <KeyPairsLine
                  encode={item.code}
                  key={item.id}
                  checked={
                    currentCategory()
                      ? currentCategory().dataIndex === item.id
                      : false
                  }
                  text1={index + 1}
                  text2={item.name}
                  onClick={(encode, key) => {
                    let commandStr =
                      "48 " +
                      mouseButtonMap[selectedMouseButton] +
                      " " +
                      encode;

                    console.log("We encode get " + commandStr, "key is ", key);

                    let sendObj = { ...templateObj };
                    sendObj.category = subPageIndex;
                    sendObj.dataIndex = item.id;
                    sendObj.extraData = 0;
                    clearCategoryCacheData();
                    //send Obj
                    dispatch(
                      updateCategorDataAction(
                        sendObj,
                        activeTab,
                        selectedMouseButton
                      )
                    );
                    //SendDataToHID(commandStr);
                  }}
                />
              ))}
            </div>
          </>
        )}

        {subPageIndex === 3 && (
          <>
            <NavigationBack
              text={subButtons[subPageIndex]}
              buttonClick={() => {
                setSubPageIndex(-1);
              }}
            />
            <SeparatorLine />
            {/* for Debug */}
            {/* <CustomSelect options={options} onSelect={handleOptionSelect} /> */}

            <div
              className="scrollsection"
              style={{ width: "100%", flex: 1, overflowY: "auto" }}
            >
              {keyboardMap.map((item, index) => (
                <KeyPairsLine
                  encode={item.code}
                  key={item.name}
                  text1={index + 1}
                  checked={
                    currentCategory()
                      ? currentCategory().dataIndex === item.name
                      : false
                  }
                  text2={item.name}
                  onClick={(encode) => {
                    let commandStr =
                      "48 " +
                      mouseButtonMap[selectedMouseButton] +
                      " " +
                      encode.substring(2);

                    console.log("We encode get " + commandStr, item.id);
                    // SendDataToHID(commandStr);
                    let sendObj = { ...templateObj };
                    sendObj.category = subPageIndex;
                    sendObj.dataIndex = item.name;
                    sendObj.extraData = 0;
                    clearCategoryCacheData();
                    //send Obj
                    dispatch(
                      updateCategorDataAction(
                        sendObj,
                        activeTab,
                        selectedMouseButton
                      )
                    );
                  }}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function Title({ text }) {
  return (
    <div
      style={{
        display: "flex",
        marginTop: "15px",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <p
        style={{
          margin: "2px 0 2px 0",
          fontSize: "0.7rem",
        }}
      >
        {text}
      </p>

      <img
        src="/arrow-down.png"
        style={{
          margin: "2px 0 2px 5px",
          width: "18px",
          height: "18px",
        }}
      ></img>
    </div>
  );
}
export default ButtonComp;
