import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import "./MainSubBlock.css";
import MouseIndicator from "./MouseIndicator";
import {
  restoreAllConfigures,
  connectDeviceAction,
  disconnectDeviceAction,
  sendDataAction,
} from "./webhid/action";

import { useSelector, useDispatch } from "react-redux";
import DPISelector from "./DPISelector";
import StatusBar from "./StatusBar";
import getConnectedDevice from "./webhid/devices";

function ConnectComp() {
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);
  const common = useSelector((state) => state.common);
  const isConnected = common.isConnected;
  const fatalLog = common.fatalLog;

  const dispatch = useDispatch();

  const [statusInfo, setStatusInfo] = useState("");

  function connectDevice() {
    try {
      dispatch(connectDeviceAction());
    } catch (e) {
      console.warn("We catured exception", e);
    }
  }

  async function debugAction(index) {
    const device = await getConnectedDevice();
    console.log("Action" + index + " is clicked", "device is", device);
    if (device) {
      if (index === 1) {
        console.log("We are in swap left-right");
        const sendData = [0x02, 0x04];
        try {
          await device.sendReport(0xa0, new Uint8Array(sendData));
        } catch (e) {
          console.log("We encounter error", e);
          setStatusInfo("We encounter error " + e);
        }
      } else if (index === 2) {
        console.log("We are restore left-right");
        const sendData = [0x46, 0x00, 0x03];
        try {
          await device.sendReport(0xa0, new Uint8Array(sendData));
        } catch (e) {
          console.log("We encounter error", e);
          setStatusInfo("We encounter error " + e);
        }
      } else if (index === 3) {
        console.log("We are disconnecting device is:", device);

        try {
          if (await device.opened) {
            await device.close();
            dispatch(disconnectDeviceAction());
            console.log(`Device ${device.productName} has been disconnected.`);
          } else {
            console.log("Device is already closed.");
          }
        } catch (error) {
          console.error(`Failed to disconnect device: ${error}`);
        }
      }
    }
  }

  return (
    <div
      className="main-content"
      style={{ whiteSpace: "nowrap", position: "relative" }}
    >
      <div
        className="main-panel"
        style={{
          width: "40%",
          height: "55vh",
          margin: "0 30%",
          backgroundColor: "#f4f4f4",
        }}
      >
        <div
          style={{
            width: "35%",
            margin: "0 auto",
            marginBottom: "25px",
          }}
        >
          <img
            src="/mouse.png"
            alt="Mouse"
            style={{
              height: "100%",
              display: "block",
              width: "100%",
              maxWidth: "400px",
              overflow: "hidden",
              margin: "auto",
              cursor: "pointer",
            }}
            className="mouse-image"
            onClick={() => {
              connectDevice();
            }}
          />
        </div>

        <div className="reset-button-section" style={{ font: "2rem" }}>
          <p style={{ fontSize: "0.7rem", margin: "0 auto" }}>
            点击鼠标进行连接以继续
          </p>
        </div>

        <div
          className="hintcontinue"
          style={{
            color: isConnected ? "green" : "red",
            width: "50%",
            padding: "0 5px",
            margin: "1vh auto 0 auto",
            border: "0px solid red",
            background: `repeating-linear-gradient(
              135deg, 
              #e0e0e0, 
              #e0e0e0 2px,
              #ffffff 3px,
              #ffffff 4px 
            )`,
          }}
        >
          <p style={{ margin: "0 auto" }}>
            {/* {isConnected ? "Connected" : "Disconnected"} */}&nbsp;
          </p>
        </div>
      </div>
      <StatusBar showText={fatalLog} onClickProp={debugAction} />
    </div>
  );
}

export default ConnectComp;
