import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import "./Sidebar.css";
import { useSelector, useDispatch } from "react-redux";
import getConnectedDevice from "./webhid/devices";
import {
  disconnectDeviceAction,
  sendFatalLogAction,
  userClickMenuAction,
} from "./webhid/action";
import { useNavigate } from "react-router-dom";
import { connectedDeviceAction } from "./webhid/action";

const Sidebar = () => {
  const { t, i18n } = useTranslation();
  const common = useSelector((state) => state.common);
  let isConnected = common.isConnected;

  const productName = common.productName;
  const menu = common.menu;

  //console.log("We current select is " + menu);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchDevice = async () => {
      const device = await getConnectedDevice();
      if (device === null) {
        dispatch(connectedDeviceAction(false, null));
      }
      // You can use the device here
    };

    fetchDevice(); // Call the async function
  }, []); // Empty dependency array to run only on mount

  function MenuClicked(menuName) {
    console.log("We clicked the menu", menuName);

    if (isConnected) {
      dispatch(userClickMenuAction(menuName));
      navigate("/" + menuName);
    } else {
      dispatch(sendFatalLogAction("Device disconnected, please connect"));
    }
  }

  return (
    <div className="sidebar">
      <h1
        style={{ marginTop: "15vh" }}
        onClick={() => {
          console.log("We click the product name.");
          dispatch(userClickMenuAction("index"));
          navigate("/");
        }}
      >
        {isConnected ? productName : t("sidebar_productname")}
      </h1>

      <ul>
        <li
          onClick={() => MenuClicked("buttons")}
          className={isConnected && menu === "buttons" ? "active" : ""}
        >
          {t("sidebar_buttons")}
        </li>
        <li
          onClick={() => MenuClicked("dpi")}
          className={isConnected && menu === "dpi" ? "active" : ""}
        >
          {t("sidebar_dpi")}
        </li>
        <li
          onClick={() => MenuClicked("macros")}
          className={isConnected && menu === "macros" ? "active" : ""}
        >
          {t("sidebar_macro")}
        </li>
        <li
          onClick={() => MenuClicked("settings")}
          className={isConnected && menu === "settings" ? "active" : ""}
        >
          {t("sidebar_setting")}
        </li>
      </ul>

      <div>
        <div className="links" style={{ textAlign: "left" }}>
          <a
            style={{ padding: "5px" }}
            target="_blank"
            href="https://iqunix.com"
          >
            IQUINIX.COM &nbsp;&nbsp;&nbsp;&gt;&gt;&gt;
          </a>
          <a
            style={{ padding: "5px" }}
            target="_blank"
            href="https://iqunix.store"
          >
            IQUINIX.STORE &gt;&gt;&gt;
          </a>
        </div>
        <img
          style={{
            marginBottom: "50px",
            marginTop: "50px",
            width: "50%",
            maxWidth: "80%",
          }}
          src="/white-logo_200x.avif"
          alt="IQUNIX.store"
        ></img>
      </div>
    </div>
  );
};

export default Sidebar;
