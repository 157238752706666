import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef, useCallback } from "react";
import "./MainSubBlock.css";

import { useSelector, useDispatch } from "react-redux";
import NavigationBack from "./components/NavigationBack";
import SeparatorLine from "./components/SeparatorLine";
import CenteredButton from "./components/CenteredButton";
import GrayBlock from "./components/GrayBlock";

import StatusBar from "./StatusBar";
import getConnectedDevice from "./webhid/devices";
import { useNavigate } from "react-router-dom";
import SendDataToHID from "./webhid/sendData";
import {
  sendMacroAddedAction,
  sendMacroDeletedAction,
  sendMacroReplaceAction,
} from "./webhid/action";
import { type } from "@testing-library/user-event/dist/type";

function MacroComp() {
  async function debugAction(index, userInput) {
    const device = await getConnectedDevice();
    if (index === 1) {
      console.log("We are in swap left-right");
      const sendData1 = [0x46, 0x01, 0x03];
      try {
        await device.sendReport(0xa0, new Uint8Array(sendData1));
      } catch (e) {
        console.log("We encounter error", e);
        return;
        //setStatusInfo("We encounter error " + e);
      }
      console.log("We set success");
    } else if (index === 2) {
      console.log("We are restore left-right");
      const sendData = [0x46, 0x00, 0x03];
      try {
        await device.sendReport(0xa0, new Uint8Array(sendData));
      } catch (e) {
        console.log("We encounter error", e);
        //setStatusInfo("We encounter error " + e);
      }
    } else if (index === 3) {
      console.log("We are disconnecting device");
      // if (device.opened) {
      //   try {
      //     await device.close();
      //     //dispatch(disconnectDeviceAction());
      //     console.log(`Device ${device.productName} has been disconnected.`);
      //   } catch (error) {
      //     console.error(`Failed to disconnect device: ${error}`);
      //   }
      // } else {
      //   console.log("Device is already closed.");
      // }
    } else if (index === 4) {
      SendDataToHID(userInput);
    }
  }

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const [recording, setRecording] = useState(false);

  const mouseKeyMap = [
    "鼠标左键",
    "鼠标中键",
    "鼠标右键",
    "鼠标后退键",
    "鼠标前进键",
  ];

  const recordEventFunc = useCallback((event) => {
    let eventName = null;
    if (event instanceof KeyboardEvent) {
      eventName = "键盘" + event.key + "键";
    } else if (event instanceof PointerEvent) {
      eventName = mouseKeyMap[event.button];
    }

    setRecordActions((prev) => {
      let duration = 0;
      if (prev.length > 0) {
        duration = event.timeStamp - prev[prev.length - 1].timeStamp;
      }

      let action = {
        text1: eventName,
        timeStamp: event.timeStamp,
        duration: (duration / 1000.0).toFixed(2),
      };
      return [...prev, action];
    });
  }, []);

  function onActionButtonClicked(action) {
    if (recording && action !== "record") {
      // ignore all other clicks.
      return;
    }

    if (schemeSelect === -1) {
      return;
    }

    console.log("action " + action + " is clicked");
    if (action === "record") {
      setRecording((prev) => {
        const recording = !prev;
        console.log("Recording state is now:", recording);
        if (recording) {
          console.log("Begin to record -----------------------");
          setRecordActions([]);
          window.addEventListener("click", recordEventFunc);
          window.addEventListener("keydown", recordEventFunc);
          window.addEventListener("keyup", recordEventFunc);
          window.addEventListener("contextmenu", recordEventFunc);
        } else {
          window.removeEventListener("click", recordEventFunc);
          window.removeEventListener("keydown", recordEventFunc);
          window.removeEventListener("keyup", recordEventFunc);
          window.removeEventListener("contextmenu", recordEventFunc);
          console.log("End to record -----------------------");
        }
        return recording;
      });
    } else if (action === "edit") {
    } else if (action === "save") {
      //recordActions;
      setRecording(false);
      if (schemeSelect !== -1) {
        let name = customSchemes[schemeSelect].name;

        action = {
          name: name,
          actionList: recordActions,
        };

        dispatch(sendMacroReplaceAction(schemeSelect, action));
      }
    } else if (action === "reset") {
      setRecordActions([]);
    }
  }

  //宏录制临时存储。
  const [recordActions, setRecordActions] = useState([]);

  const pButtonRef = useRef(null); // Create a ref for the input
  const [buttonHeight, setButtonHeight] = useState(0); // State to store input height

  useEffect(() => {
    if (pButtonRef.current) {
      setButtonHeight(pButtonRef.current.clientHeight * 0.32); // Set the height based on the input's clientHeight
    }
  }, [pButtonRef]);
  const macroData = useSelector((state) => state.macro);

  const customSchemes = macroData.macroLists;

  const [schemeSelect, setSchemeSelect] = useState(-1);

  return (
    <div className="main-content" style={{ whiteSpace: "nowrap" }}>
      <div className="main-panel" style={{ justifyContent: "flex-start" }}>
        <div>
          <NavigationBack text="宏设置" buttonClick={() => {}} />
        </div>
        <SeparatorLine />
        <div
          className="buttonGroup"
          style={{
            display: "flex",
            marginTop: "5px",
            flexDirection: "row",
            color: schemeSelect === -1 ? "gray" : "black",
          }}
        >
          <div
            className="buttonGroup1"
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              font: "2rem",
              padding: "0px auto",
              margin: "10px 0px 0px 0px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            {/* button record */}
            <p
              className="reset-button"
              ref={pButtonRef}
              style={{
                fontSize: "0.7rem",
                border: recording ? "1px solid red" : "1px solid #ddd",
                color: recording || schemeSelect === -1 ? "#ddd" : "black",
                cursor: "pointer",
                margin: "0px",
                padding: "0.6vh 1.1vh",
                borderRadius: "4px",
                backgroundColor: "transparent",
              }}
              onClick={(event) => {
                event.stopPropagation(); // not including this event.
                onActionButtonClicked("record");
              }}
            >
              <span
                style={{
                  width: `${buttonHeight}px`,
                  //width: "5px",
                  minWidth: "1vh",
                  minHeight: "1vh",
                  display: "inline-block",
                  //height: "5px",
                  height: `${buttonHeight}px`,
                  margin: "auto 1vh auto 0",
                  backgroundColor: "red",
                  borderRadius: recording ? "0" : "50%",
                }}
              ></span>
              <span>录制</span>
            </p>

            {/* button edit. */}
            <p
              className="reset-button"
              ref={pButtonRef}
              style={{
                fontSize: "0.7rem",
                border: "1px solid #ddd",
                cursor: "pointer",
                margin: "0px 0 0 0.5vh",
                padding: "0.6vh 1.1vh",
                borderRadius: "4px",
                backgroundColor: "transparent",
                color: recording || schemeSelect === -1 ? "#ddd" : "black",
              }}
              onClick={() => {
                onActionButtonClicked("edit");
              }}
            >
              <span>编辑</span>
            </p>
          </div>

          <div
            className="buttonGroup2"
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              justifyContent: "flex-end",
              color: recording || schemeSelect === -1 ? "#ddd" : "black",
              font: "2rem",
              padding: "0px auto",
              margin: "10px 0px 0px 0px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            {/* button save */}
            <p
              className="reset-button"
              ref={pButtonRef}
              style={{
                fontSize: "0.7rem",
                border: "1px solid #ddd",
                cursor: "pointer",
                margin: "0px 0 0 0.5vh",
                padding: "0.6vh 1.1vh",
                borderRadius: "4px",
                backgroundColor: "transparent",
              }}
              onClick={() => {
                onActionButtonClicked("save");
              }}
            >
              <span>保存</span>
            </p>

            {/* button reset */}
            <p
              className="reset-button"
              ref={pButtonRef}
              style={{
                fontSize: "0.7rem",
                border: "1px solid #ddd",
                cursor: "pointer",
                margin: "0px 0 0 0.5vh",
                padding: "0.6vh 1.1vh",
                borderRadius: "4px",
                backgroundColor: "transparent",
              }}
              onClick={() => {
                if (schemeSelect !== -1) {
                  onActionButtonClicked("reset");
                }
              }}
            >
              <span>重置</span>
            </p>
          </div>
        </div>
        {/* Button Ends. */}
        {schemeSelect === -1 && <EmptyHint text="请在右侧选择一个方案。" />}

        {/* {schemeSelect !== -1 &&
          customSchemes[schemeSelect].actionList.length === 0 && (
            <EmptyHint text="开始录制连动作或手动插入单个动作。" />
          )} */}

        {schemeSelect !== -1 &&
          customSchemes[schemeSelect].actionList.length >= 0 && (
            <div
              className="scroll-part"
              style={{
                margin: "5px 0",
                flex: "1",
                height: "150px",
                fontSize: "0.6rem",
                overflowY: "auto",
              }}
            >
              {recordActions.map((item, index) => (
                <GrayBlock
                  key={index}
                  text1={item.text1}
                  text2={`${item.duration}s`}
                />
              ))}
            </div>
          )}
      </div>

      <div
        className="sub-panel"
        style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            whiteSpace: "nowrap",
            justifyContent: "left",
            alignItems: "center",
          }}
          onClick={() => {}}
        >
          <p
            style={{
              fontSize: "0.8rem",
              fontWeight: "bold",
            }}
          >
            宏列表
          </p>
          <div style={{ flex: 1 }}></div>

          <div style={{ display: "flex" }}>
            <img
              src="/arrow-upload.png"
              style={{
                width: "1.8vh",
                height: "1.8vh",
                marginLeft: "1.5vh",
                cursor: "pointer",
              }}
            />
            <img
              src="/arrow-download.png"
              style={{
                width: "1.8vh",
                marginLeft: "1.5vh",
                height: "1.8vh",
                cursor: "pointer",
              }}
            />

            <img
              src="/plus.png"
              style={{
                width: "1.8vh",
                marginLeft: "1.5vh",
                height: "1.8vh",
                cursor: "pointer",
              }}
              onClick={() => {
                let newScheme = {
                  name: "自定义方案-" + macroData.macroIndex,
                  actionList: [],
                };
                dispatch(sendMacroAddedAction(newScheme));
              }}
            />
          </div>
        </div>

        <SeparatorLine />
        {customSchemes.length === 0 && (
          <EmptyHint text="你尚未创建任何宏。点击+可添加宏到此列表。" />
        )}

        {customSchemes.length > 0 && (
          <>
            {customSchemes.map((item, index) => (
              <CustomScheme
                text={item.name}
                key={index}
                select={schemeSelect === index}
                imgClick={(img, indexToDelete) => {
                  if (img === "trash") {
                    let cloneList = [...customSchemes];

                    if (index > -1 && index < cloneList.length) {
                      console.log("We are trying to delete index", index);
                      dispatch(sendMacroDeletedAction(index));

                      setSchemeSelect(-1);
                      setRecordActions([]);
                    }
                  }

                  if (img === "edit") {
                    const userInput = window.prompt("Please enter your input:");
                    console.log("User input:", userInput);
                    if (userInput && userInput.trim().length > 0) {
                      let cloneList = JSON.parse(JSON.stringify(customSchemes));
                      cloneList[index].name = userInput;
                      dispatch(sendMacroReplaceAction(index, cloneList[index]));
                    }
                  }

                  if (img === "div") {
                    console.log("We set null emtpy ", index);
                    setSchemeSelect(index);
                    setRecordActions(customSchemes[index].actionList);
                  }
                }}
              />
            ))}
          </>
        )}
      </div>
      <StatusBar showText={"fatalLog"} onClickProp={debugAction} />
    </div>
  );
}

const EmptyHint = ({ text }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        flex: 1,
      }}
    >
      <p style={{ color: "#ccc" }}>{text}</p>
    </div>
  );
};
const CustomScheme = ({ select, key, text, imgClick }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        cursor: "pointer",
      }}
      onClick={() => {
        imgClick("div", key);
      }}
    >
      <div
        style={{
          backgroundColor: select ? "#444" : "#eee",
          marginTop: "10px",
          width: "100%",
          borderRadius: "5px",
          display: "flex",
          padding: "5px",
        }}
      >
        <p
          style={{
            color: "#ccc",
            margin: "1px auto",
            fontSize: "0.7rem",
          }}
        >
          {text}
        </p>
        <div style={{ flex: 1 }}></div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="/edit.png"
            style={{
              width: "1.8vh",
              marginLeft: "1.5vh",
              height: "1.8vh",
              cursor: "pointer",
            }}
            onClick={(e) => {
              imgClick("edit", key);
              e.stopPropagation();
              e.preventDefault();
            }}
          />

          <img
            src="/trash.png"
            style={{
              width: "1.8vh",
              cursor: "pointer",
              marginLeft: "1.5vh",
              height: "1.8vh",
            }}
            onClick={(e) => {
              imgClick("trash", key);
              e.stopPropagation();
              e.preventDefault();
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default MacroComp;
