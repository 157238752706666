import "./i18n";
import React, { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import ConnectPage from "./pages/ConnectPage";
import NotFoundPage from "./pages/NotFoundPage";
import SettingsPage from "./pages/SettingsPage";
import { useTranslation } from "react-i18next";
import ButtonsPage from "./pages/ButtonsPage";
import { useDispatch } from "react-redux";
import { disconnectDeviceAction } from "./webhid/action";
import DpiPage from "./pages/DpiPage";
import MacroPage from "./pages/MacroPage";
import { useSelector } from "react-redux";
import commonEn from "./locales/en.json";
import commonZh from "./locales/zh.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const App = () => {
  const dispatch = useDispatch();

  const userLanguage = navigator.language || navigator.userLanguage;

  const common = useSelector((state) => state.common);
  const appConfig = common.appConfig;
  const langId = appConfig.lang || userLanguage.substring(0, 2);

  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: commonEn, // 使用 common.json 的内容
      },
      zh: {
        translation: commonZh, // 使用 common.json 的内容
      },
    },
    lng: langId, // 默认语言
    fallbackLng: langId,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      console.log("User refreshed the page 111.");
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      console.log("User refreshed the page 222.");
      dispatch(disconnectDeviceAction());
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<ConnectPage />} />
        <Route path="/buttons" element={<ButtonsPage />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/dpi" element={<DpiPage />} />
        <Route path="/macros" element={<MacroPage />} />

        {/* Default route */}
        <Route path="*" element={<Navigate to="/not-found" />} />
        {/* Not Found route */}
        <Route path="/not-found" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default App;
