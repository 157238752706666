import { useState } from "react";

function SquareButton({ names, checked, color, size, onClickFunc }) {
  function onButtonClicked() {
    onClickFunc(names, !checked);
  }

  return (
    <div
      className="squarebutton-outer"
      style={{
        width: size,
        height: "100%",
        padding: "0px",
        margin: "12px, 0, -7px, 2vh",
        alignSelf: "bottom",
        height: size,
        display: "flex",
        border: "1px solid black",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "3px",
      }}
      onClick={() => onButtonClicked()}
    >
      {checked && (
        <div
          style={{
            width: "50%",
            backgroundColor: checked ? color : "white",
            height: "50%",
          }}
        ></div>
      )}
    </div>
  );
}

export default SquareButton;
