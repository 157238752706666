import "./ConfNavigation.css";

function ConfNavigation({ data, activeTab, onClickFunc }) {
  return (
    <div className="conf-navigation">
      {data.map((item, index) => (
        <button
          key={index}
          className={activeTab === index ? "active" : ""}
          onClick={() => onClickFunc(index)}
        >
          {item}
        </button>
      ))}
    </div>
  );
}

export default ConfNavigation;
