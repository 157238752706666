function SeparatorLine({}) {
  return (
    <div
      style={{
        width: "100%",
        height: "2px",
        border: "1px slid red",
        backgroundColor: "#eee",
      }}
    ></div>
  );
}
export default SeparatorLine;
