import "./TabNavigation.css";

function TabNavigation({ data, activeTab, onClickFunc }) {
  return (
    <div
      className="tab-navigation"
      style={{
        display: "flex",
        justifyContent: "flex-start",
        borderBottom: "2px solid #eee",
      }}
    >
      {data.map((item, index) => (
        <button
          key={index}
          style={{ marginRight: "5px" }}
          className={activeTab === index + 1 ? "active" : ""}
          onClick={() => onClickFunc(index + 1)}
        >
          {item}
        </button>
      ))}
    </div>
  );
}

export default TabNavigation;
