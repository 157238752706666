import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import "./MainSubBlock.css";

import {
  restoreAllConfigures,
  connectDeviceAction,
  disconnectDeviceAction,
  sendDataAction,
  changeAppLanguage,
} from "./webhid/action";

import { useSelector, useDispatch } from "react-redux";
import Subtile from "./components/Subtitle";
import GetAppInfo from "./GetAppInfo";
function SettingComp() {
  const softwareVersion = "1.0.0.8";

  const common = useSelector((state) => state.common);
  const productName = common.productName;

  const dispatch = useDispatch();

  const [statusInfo, setStatusInfo] = useState("");

  const [language, setLanguage] = useState("en");

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  function connectDevice() {
    try {
      dispatch(connectDeviceAction());
    } catch (e) {
      console.warn("We catured exception", e);
    }
  }

  const [newVersionUpgrade, setNewVersionUpgrade] = useState(null);

  async function checkUpdate() {
    try {
      const response = await fetch(
        "https://rockzhang.com/release/output-metadata.json"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setNewVersionUpgrade(data.version);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      console.log("Key pressed:", event.key, typeof event.key); // You can handle the key event here
      if (event.key === "Escape") {
        setNewVersionUpgrade(null);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div
      className="main-content"
      style={{ whiteSpace: "nowrap", position: "relative" }}
    >
      <div
        className="main-panel"
        style={{
          width: "40%",
          height: "55vh",
          margin: "0 30%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          backgroundColor: "white",
        }}
      >
        {newVersionUpgrade && (
          <>
            <div
              className="overlay"
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                borderRadius: "8px",
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                zIndex: "999",
              }}
            ></div>
            <div
              style={{
                className: "popupedDialog",
                position: "absolute",
                top: "50%",
                left: "50%",
                width: "60%",
                height: "35%",
                borderRadius: "6px",
                transform: "translate(-50%, -50%)",
                backgroundColor: "white",
                boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                zIndex: 1000,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                padding: "16px",
              }}
            >
              <div>
                <p
                  style={{
                    fontWeight: "bold",
                    margin: "0",
                    fontSize: "0.9rem",
                  }}
                >
                  发现新版本，是否立即更新
                </p>
                <p
                  style={{
                    textAlign: "left",
                    margin: "2px 0px",
                    color: "gray",
                  }}
                >
                  {"vBeta:" + softwareVersion}
                </p>
              </div>

              <ul style={{ padding: "0 16px", margin: "0" }}>
                <li style={{ textAlign: "left" }}>UI优化、修复部分bug </li>
                <li style={{ textAlign: "left" }}>新增宏功能</li>
              </ul>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className="reset-button"
                  style={{
                    fontSize: "0.7rem",
                    border: "1px solid #ddd",
                    cursor: "pointer",
                    padding: "0.6vh 3.1vh",
                    borderRadius: "4px",
                    margin: "8px 8px 8px 8px",
                    color: "white",
                    backgroundColor: "black",
                  }}
                  onClick={() => {
                    setNewVersionUpgrade(null);
                  }}
                >
                  更新
                </button>

                <button
                  className="reset-button"
                  style={{
                    fontSize: "0.7rem",
                    border: "1px solid #ddd",
                    cursor: "pointer",
                    padding: "0.6vh 3.1vh",
                    borderRadius: "4px",
                    margin: "8px 8px 8px 8px",
                  }}
                  onClick={() => {
                    setNewVersionUpgrade(null);
                  }}
                >
                  取消
                </button>
              </div>
            </div>
          </>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            margin: "5vh 5vh 0 5vh",
            fontSize: "0.7rem",
          }}
        >
          <Subtile text="设备规格" />
          <p style={{ margin: "8px 0px", textAlign: "left" }}>
            {"设备名称:" + productName}
          </p>
          <p style={{ margin: "8px 0px", color: "gray", textAlign: "left" }}>
            {"鼠标固件版本:" + softwareVersion}
          </p>
          <button
            className="reset-button"
            style={{
              fontSize: "0.7rem",
              border: "1px solid #ddd",
              cursor: "pointer",
              padding: "0.6vh 1.1vh",
              borderRadius: "4px",
              margin: "8px 0px",
              color: "white",
              backgroundColor: "black",
            }}
            onClick={async () => {
              await checkUpdate();
            }}
          >
            检查更新
          </button>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            margin: "0 5vh",
            marginBottom: "2vh",
          }}
        >
          <Subtile text="语言" />
          <select
            value={common.appConfig.lang}
            style={{ margin: "8px 0px", fontSize: "0.72rem" }}
            onChange={(e) => {
              console.log("We get the language " + e.target.value);
              dispatch(changeAppLanguage(e.target.value));
              i18n.changeLanguage(e.target.value);
            }}
          >
            <option value="en">English</option>
            <option value="zh">中文</option>
          </select>
        </div>
        <div
          style={{
            display: "flex",
            margin: "0 5vh",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginBottom: "2vh",
          }}
        >
          <Subtile text="其他设置" />
          <button
            className="reset-button"
            style={{
              fontSize: "0.7rem",
              border: "1px solid #ddd",
              cursor: "pointer",
              margin: "8px 0px",
              padding: "0.6vh 1.1vh",
              borderRadius: "4px",
              color: "white",
              backgroundColor: "black",
            }}
          >
            恢复出厂设置
          </button>
        </div>

        <GetAppInfo />
      </div>
    </div>
  );
}

export default SettingComp;
