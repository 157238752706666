function GrayBlock({ text1, text2 }) {
  return (
    <div
      style={{
        backgroundColor: "#efefef",
        marginTop: "10px",
        height: "4vh",
        display: "flex",
        width: "100%",
      }}
    >
      <div
        className="part1"
        style={{
          display: "flex",
          height: "100%",
          width: "50%",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <img
          style={{ maxHeight: "80%", maxWidth: "80%" }}
          src="/arrow-left.png"
        ></img>
        <p style={{ maxHeight: "80%", maxWidth: "80%" }}>{text1}</p>
      </div>

      <div
        className="part2"
        style={{
          display: "flex",
          height: "100%",
          width: "50%",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <img
          style={{ maxHeight: "25px", height: "100%", maxWidth: "25px" }}
          src="/arrow.png"
        ></img>
        <p>{text2}</p>
      </div>
    </div>
  );
}
export default GrayBlock;
