// src/reducers/index.js
import { combineReducers } from "redux";
import commonReducer from "./commonReducer";
import macroReducer from "./macroreducer";

const rootReducer = combineReducers({
  common: commonReducer,
  macro: macroReducer,
});

export default rootReducer;
