import SquareButton from "./SquareButton";
const CheckboxText = ({ checked, text, index, buttonClick }) => {
  //console.log("CheckboxTest ", checked, text, index);
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        whiteSpace: "nowrap",
        alignItems: "flex-end",
        margin: "5px auto",
      }}
    >
      <SquareButton
        names={index}
        checked={checked}
        color="orange"
        size="2vh"
        onClickFunc={buttonClick}
      />
      <p
        style={{
          fontSize: "0.72rem",
          margin: "0 0 0 0.6vh",
          paddingTop: "5px",
        }}
      >
        {text}
      </p>
    </div>
  );
};

export default CheckboxText;
