import getConnectedDevice from "./devices";

async function SendDataToHID(data) {
  const device = await getConnectedDevice();

  let command = data.trim();
  const sendData = command.split(" ").map((x) => parseInt(x, 16)); // Convert string to array of numbers
  console.warn(
    "We are sending custom command:",
    sendData.map((num) => "0x" + num.toString(16).padStart(2, "0")).join(" ")
  );

  try {
    await device.sendReport(0xa0, new Uint8Array(sendData));
  } catch (e) {
    console.log("Sending custom command <" + command + "> error", e);
    //setStatusInfo("We encounter error " + e);
  }
}

export default SendDataToHID;
