import { useState } from "react";

function KeyPairsLine({ encode, text1, checked, text2, onClick }) {
  return (
    <div
      style={{
        width: "100%",
        paddingBottom: "6px",
        height: "3vh",
        display: "flex",
        fontSize: "0.6rem",
        flexDirection: "row",
        borderBottom: "1px solid #eee",
        justifyContent: "space-around",
        alignItems: "center",
        cursor: "pointer",

        backgroundColor: checked ? "#333" : "transparent",
      }}
      onClick={() => {
        onClick(encode);
      }}
    >
      <span
        style={{
          margin: "10px 0",
          alignSelf: "center",
          width: "40%",
          height: "100%",
          lineHeight: "50px",
          flex: 1,
          textAlign: "left",
        }}
      >
        {text1}
      </span>

      <span
        style={{
          flex: 1,
          textAlign: "left",
          margin: "10px",
          alignSelf: "center",
          width: "40%",
          height: "100%",
          lineHeight: "50px",
          color: "#ccc",
        }}
      >
        {text2}
      </span>
    </div>
  );
}

export default KeyPairsLine;
