import React from "react";

const GetAppInfo = () => {
  const commitId = process.env.REACT_APP_COMMIT_ID;
  const commitDate = process.env.REACT_APP_COMMIT_DATE;

  return (
    <div style={{ color: "white" }}>
      <p style={{ color: "white", textAlign: "left" }}>
        当前提交 ID：{commitId}
      </p>
      <p style={{ color: "white", textAlign: "left" }}>
        提交时间：{commitDate}
      </p>
    </div>
  );
};

export default GetAppInfo;
