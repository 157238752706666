export const systemKeyMap = [
  {
    id: "1",
    name: "鼠标左键",
    code: "04 01 00",
  },
  {
    id: "2",
    name: "鼠标右键",
    code: "04 02 00",
  },
  {
    id: "3",
    name: "鼠标中键",
    code: "04 04 00",
  },
  {
    id: "4",
    name: "前进键",
    code: "04 10 00",
  },
  {
    id: "5",
    name: "后退键",
    code: "04 08 00",
  },
  {
    id: "6",
    name: "播放/停止",
    code: "02 CD 00",
  },
  {
    id: "7",
    name: "音量-",
    code: "02 EA 00",
  },
  {
    id: "8",
    name: "音量+",
    code: "02 E9 00",
  },
  {
    id: "9",
    name: "静音",
    code: "02 E2 00",
  },
  {
    id: "10",
    name: "播放器",
    code: "02 83 01",
  },
  {
    id: "11",
    name: "上一首",
    code: "02 B6 00",
  },
  {
    id: "12",
    name: "下一首",
    code: "02 B5 00",
  },
  {
    id: "13",
    name: "邮件",
    code: "02 8A 01",
  },
  {
    id: "14",
    name: "计算器",
    code: "02 92 01",
  },
  {
    id: "998",
    name: "默认键",
    code: "FF FF FF",
  },
  {
    id: "997",
    name: "火力键",
    code: "04 01 00 00 02",
  },
  {
    id: "996",
    name: "三连击",
    code: "04 01 00 00 03",
  },
  {
    id: "9999",
    name: "无",
    code: "0F 00 01",
  },
  //   {
  //     id: "999",
  //     name: "自定义",
  //     code: "",
  //   },
];
