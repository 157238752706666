function CenteredButton({ buttonText, onClick, disabled, fontColor }) {
  return (
    <div
      style={{
        font: "2rem",
        padding: "0px auto",
        // backgroundColor: "#ffffff",
        margin: "10px 8px 10px 8px",
        //width: "100%",
        borderRadius: "4px",
        cursor: "pointer",
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      <button
        className="reset-button"
        style={{
          fontSize: "0.7rem",
          border: "1px solid #ddd",
          cursor: "pointer",
          padding: "0.1vh 2.2vh",
          borderRadius: "4px",
          color: fontColor ? fontColor : "black",
          backgroundColor: "transparent",
        }}
        disabled={disabled}
        onClick={() => {
          onClick();
        }}
      >
        {buttonText}
      </button>
    </div>
  );
}

export default CenteredButton;
